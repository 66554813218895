import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import Divider from '@mui/material/Divider';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import { Button,  FormControl, InputLabel, Popover, MenuItem, Select, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton'
import { useTranslation } from 'react-i18next';


 

firebase.initializeApp({
  apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
  authDomain: "masig-online.firebaseapp.com",
  projectId: "masig-online",
  storageBucket: "masig-online.appspot.com",
  messagingSenderId: "1064560601004",
  appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
  measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const InputField = styled(TextField)({
    marginBottom: '1rem',
});

const InputField2 = styled(TextField)({
    marginTop: '1rem',
});


export default function IpotecaDdetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [price, setPrice] = useState("");
    const [deliveryPrice, setDeliveryPrice] = useState("0.00");
    const [amount, setAmount] = useState("");
    const [name, setName] = useState("");
    const [bancBeneficiar, setBancBeneficiar] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("online");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [adress, setAdress] = useState("");
    const [startDay, setStartDay] = useState("");  
    const startDate = dayjs(startDay, "DD.MM.YYYY");
    const endDay = startDate.add(1, 'year').subtract(1, 'day').format('DD.MM.YYYY');
    const [loading, setLoading] = useState(true);
    const [passNumber, setPassNumber] = useState("");
    const [idnx, setIdnx] = useState('');
    const [insuredBase, setInsuredBase] = useState(""); 
    const [imobilType, setImobilType] = useState('');
    const [imobilValue, setImobilValue] = useState('');
    const [idnxTouched, setIdnxTouched] = useState(false);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const openicon1 = Boolean(anchorEl1);
    const idicon1 = openicon1 ? 'simple-popover-1' : undefined;
    const openicon2 = Boolean(anchorEl2);
    const idicon2 = openicon2 ? 'simple-popover-2' : undefined;
    const { i18n, t } = useTranslation();


    useEffect(() => {
        if (price < 500 && deliveryMethod === "deliveryOnAdress") {
          setDeliveryPrice(59.00);
        } else {
          setDeliveryPrice(0.00);
        }
      }, [price, deliveryMethod]);

      useEffect(() => {
        const amount = Number(price) + Number(deliveryPrice);
        setAmount(Number(amount.toFixed(2)));
      }, [price, deliveryPrice]);


      const handleiconClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
      };
    
      const handleiconClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
      };
    
      const handleiconClose1 = () => {
        setAnchorEl1(null);
      };
    
      const handleiconClose2 = () => {
        setAnchorEl2(null);
      };


      const handleIdnxChange = (e) => {
        setIdnx(e.target.value);
        setIdnxTouched(true);
      };

    const validateIdnxLastDigit = (idnxValue) => {
        if (/^[012]\d{0,12}$/.test(idnxValue)) {
            const D = (
            (parseInt(idnxValue[0])+parseInt(idnxValue[3])+parseInt(idnxValue[6])+parseInt(idnxValue[9]))*7 +
            (parseInt(idnxValue[1])+parseInt(idnxValue[4])+parseInt(idnxValue[7])+parseInt(idnxValue[10]))*3 +
            (parseInt(idnxValue[2])+parseInt(idnxValue[5])+parseInt(idnxValue[8])+parseInt(idnxValue[11]))
            ) % 10;
            return D === parseInt(idnxValue[12]);            
            } else {
            return false;
        }
    }   

    const isIdnxValid = validateIdnxLastDigit(idnx);    

  
    useEffect(() => {
        const fetchData = async () => {
        try {
            const doc = await db.collection("rcarequests2").doc(id).get();
            setIdnx(doc.data().idnx);
            setData(doc.data());
            setPrice(doc.data().price);
            setBancBeneficiar(doc.data().bancBeneficiar);
            setImobilType(doc.data().imobilType);
            setImobilValue(doc.data().imobilValue);
            setInsuredBase(doc.data().insuredBase);
            setDeliveryPrice(doc.data().deliveryPrice);
            setAmount(doc.data().amount);
            setName(doc.data().name);
            setPhoneNumber(doc.data().phoneNumber);
            setDeliveryMethod(doc.data().deliveryMethod);
            setEmail(doc.data().email);
            setAdress(doc.data().adress);
            setStartDay(doc.data().startDay);
            setLoading(false);
            } catch (error) {
            console.log(error);
            
            navigate('/'); 
        }
      };
      fetchData();
    }, [id]);

    useEffect(() => {
      const lang = localStorage.getItem("appLanguage");
      if (lang) {
        i18n.changeLanguage(lang); // Устанавливаем язык
      }
    }, [i18n]);



    const handleConfirmOrder = async (e) => {
      e.preventDefault();
      try {
        await db.collection("rcarequests2").doc(id).update({
            name,
            phoneNumber,
            deliveryMethod,
            idnx: idnx || '',
            email: email || '',
            adress: adress || '',
            deliveryPrice: deliveryPrice,
            amount,
            endDay,
            passNumber,
        });
        navigate(`/Ipoteca/${id}`);
        handlePayment();
      } catch (error) {
        console.log(error);
      }

      try {
        const response = await fetch('https://asig24.md/send-ipoteca', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id, name, phoneNumber, amount, price, email, adress, deliveryPrice, deliveryMethod, idnx, passNumber, imobilType, imobilValue, insuredBase, startDay, endDay, bancBeneficiar }),
        });
      
        if (response.ok) {
          // Handle successful submission
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle fetch error
      }
    };
  
    const handlePayment = () => {
      navigate(`/Ipoteca/order/${id}`);
    };

    const nameRegex = /^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u1E00-\u1EFF\s]*$/;

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsEmailValid(inputEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail));
    }; 


  return (    
    <div>
         <Helmet>
                <title>{t('cardsIpoteca2')}</title>
                <meta name="description" content="Calculează prețul și cumpără online asigurare pentru ipotecă și bunuri imobile. Rapid și sigur cu Asig24.md obții polița de asigurare în câteva minute." />
                <meta name="keywords" content="asigurare ipoteca, asigurare bunuri, calculator asigurare ipoteca, cumpără asigurare imobil ipoteca online, asigurări de sănătate, asigurări online" />
                <meta name="author" content="Asig24.md" />
                <meta property="og:title" content={t('cardsIpoteca2')} />
                <meta property="og:description" content="Calculează prețul și cumpără online asigurare medicală facultativă. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta property="og:url" content="https://www.asig24.md/Ipoteca" />
                <meta property="og:type" content="website"></meta>
                <link rel="canonical" href="https://www.asig24.md/Ipoteca" />
            </Helmet>        
        <Nav />

            <> {loading ? ( <div align="center" className="loading"><CircularProgress /></div> ) : ( <>

            <div className="parent">
                <div className="forma">

                    <div className="orderdetails">
                        <div className="orderdetailsrow">{t('imobil')}: <b>{imobilType ==='incapere' && t('incapere')}{imobilType ==='constructie' && t('constructie')}{imobilType ==='teren' && t('teren')}{imobilType ==='comercial' && t('comercial')}, {t('valoare')}: {imobilValue}</b></div>
                        <div className="orderdetailsrow">{t('insuredPeriod')} <b>{startDay} - {endDay}</b> {t('m12')}</div>
                        <div className="orderdetailsrow">{t('benOrg')}: <b>{bancBeneficiar}</b></div>

                        <Divider  />
                        <br></br>
                        <div className="orderdetailsrow">{t('insurancePrice')} <b>{price} {t('LEI')}</b></div>
                        {deliveryPrice > 0 && (<div className="orderdetailsrow">{t('deliveryPrice')}: <b>{deliveryPrice} {t('LEI')}</b></div>)}
                        <Divider textAlign="right"><b>{t('total')} {amount} {t('LEI')}</b></Divider>
                    </div>
        


                    <form onSubmit={handleConfirmOrder}>                        
                        

                        <InputField
                            required
                            label={t('labelName')}
                            value={name || ''}
                            inputProps={{ maxLength: 50 }}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={t('labelName')}
                            variant="outlined"
                            error={name !== "" && !nameRegex.test(name)}
                            helperText={name !== "" && !nameRegex.test(name) && `${t('nameError')}`}
                            fullWidth
                        />

                        <InputField
                            required
                            label={t('labelIdno')}
                            value={idnx || ''}
                            inputProps={{ minLength: 13, maxLength: 13, pattern: '[0-9]*' }}
                            InputProps={{
                                endAdornment: (            
                                    <InputAdornment disableTypography position="end" className='helpicon'>
                                        <IconButton onClick={handleiconClick1}  >            
                                            <HelpOutlineIcon />            
                                        </IconButton>
                                    </InputAdornment>  
                                ),                            
                            }}
                            onChange={handleIdnxChange}
                            placeholder={t('labelIdno')}
                            variant="outlined"
                            fullWidth
                            error={
                                idnxTouched &&
                                (idnx !== "" && (!/^[012]\d{0,12}$/.test(idnx) || !validateIdnxLastDigit(idnx)))
                              }
                              helperText={
                                idnxTouched &&
                                (idnx !== "" && !/^[012]\d{0,12}$/.test(idnx)
                                  ? t('labelIdnoErr')
                                  : idnx !== "" && !validateIdnxLastDigit(idnx)
                                  ? t('labelIdnoErr2')
                                  : "")
                              }
                        />
                        <Popover
                            id={idicon1}
                            open={openicon1}
                            anchorEl={anchorEl1}
                            onClose={handleiconClose1}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            >
                            <div className="popovermsg">
                            {t('popoverMesage')}
                            </div>
                        </Popover>

                        <InputField
                            required
                            label={t('docNumber')}
                            value={passNumber}
                            inputProps={{ minLength: 4, maxLength: 20 }}
                            onChange={(e) => setPassNumber(e.target.value)}
                            placeholder={t('docNumber')}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                endAdornment: (            
                                  <InputAdornment disableTypography position="end" className='helpicon'>
                                    <IconButton onClick={handleiconClick2}  >            
                                      <HelpOutlineIcon />            
                                    </IconButton>
                                  </InputAdornment>  
                                ),
                                
                            }}
                        />
                        <Popover
                            id={idicon2}
                            open={openicon2}
                            anchorEl={anchorEl2}
                            onClose={handleiconClose2}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            >
                            <div className="popovermsg">
                                {t('popoverMesage6')}
                            </div>
                        </Popover>

                        <InputField
                            required
                            label={t('labelPhone')}
                            value={phoneNumber || ''}
                            inputProps={{ 
                                minLength: 8, 
                                maxLength: 15, 
                                pattern: '^\\+?[0-9]{7,14}$',
                                type: 'tel',
                                inputMode: 'tel',                            
                            }}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="+373 69******"
                            variant="outlined" 
                            fullWidth
                        />

<FormControl fullWidth>
                            <InputLabel>{t('labelDelivery')} </InputLabel>
                                <Select 
                                    required
                                    label={t('labelDelivery')} 
                                    value={deliveryMethod || ''} onChange={(e) => setDeliveryMethod(e.target.value)}>
                                        <MenuItem value={"online"}>{t('onlineDelivery')}</MenuItem>
                                        <MenuItem value={"fromOffice"}>{t('fromOfficeDelivery')}</MenuItem>
                                        {amount > 500 && (<MenuItem value={"deliveryOnAdress"}>{t('onAdressDelivery')}</MenuItem>)}                     
                                </Select>
                        </FormControl>  

                        {deliveryMethod === "online" && (
                        <InputField2
                            label={t('labelEmail')}
                            value={email  || ''}
                            inputProps={{ maxLength: 50 }}
                            onChange={handleEmailChange}
                            placeholder={t('labelEmail')}
                            variant="outlined"
                            error={!isEmailValid}
                            helperText={!isEmailValid ? `${t('emailError')}` : ""}
                            fullWidth
                        />
                        )}

{deliveryMethod === "deliveryOnAdress" && (
                        <InputField2
                            label={t('deliveryAdress')}
                            value={adress  || ''}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => setAdress(e.target.value)}
                            placeholder={t('deliveryAdress')}
                            variant="outlined"
                            fullWidth
                            required
                        />
                        )}

                            <FormControl>
                                <FormControlLabel          
                                    control={
                                    <Checkbox required />
                                    }            
                                    label={
                                        <div className="checkbox">
                                           {t('labelCheckBox')}
                                        </div>
                                    }/>          
                            </FormControl>  
                        <br />
                        <br />
                        <div align="center" className="placeorderbutton">
                            <Button variant="contained" type="submit">{t('confirmOrderBtn')}</Button>
                        </div>
                    </form>
                        
                </div>
            </div>
        <Footer /> 
        </> )} </>
    </div>   
  
  );
}