import React from "react";
import "./Hero.css";
import insuranceImage from "./hero.png";
import { useTranslation } from 'react-i18next';

function Hero() {
  const { t } = useTranslation();

  return (
    <div className="hero-container">
      <div className="hero-text">
        <h1>{t('herotitle')}</h1>
        <p>{t('herotitle2')}</p>
        

      </div>
      <div className="image-container">
        <img src={insuranceImage} alt="Insurance Company" />
      </div>
    </div>
  );
}

export default Hero;
