import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import { Button,  FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import "./style.css";
import Alert from "@mui/material/Alert";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import Vibericon from '../../components/icons/vibericon.png';
import Whatsappicon from '../../components/icons/whatsappicon.png';
import Telegramicon from '../../components/icons/telegramicon.png';
import Messengericon from '../../components/icons/messengericon.png';
import { useTranslation } from 'react-i18next';


firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const generateDocId = () => {
    // Generate 5 random digits
    const randomDigits = Math.floor(1000000 + Math.random() * 9999999);
  
    // Generate 3 random letters
    const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
    const randomLetters = letters.charAt(Math.floor(Math.random() * letters.length)) +
                            letters.charAt(Math.floor(Math.random() * letters.length)) + 
                            letters.charAt(Math.floor(Math.random() * letters.length));
  
    // Combine digits and letters to form docId
    const docId = "MF" + randomDigits + randomLetters;
  
    return docId;
};


export default function MedicalaFacultativa() {

    const [birthDay, setBirthDay] = useState(null);
    const [birthDayChanged, setBirthDayChanged] = useState('');
    const [period, setPeriod] = useState('');
    const [periodChanged, setPeriodChanged] = useState('');
    const [insuranceType, setInsuranceType] = useState('');
    const [insuranceTypeChanged, setInsuranceTypeChanged] = useState('');
    const [price, setPrice] = useState(0);
    const [country, setCountry] = useState("");
    const [uid, setUid] = useState("");
    const [open, setOpen] = useState(false);
    const { i18n, t } = useTranslation();



    useEffect(() => {
        auth.signInAnonymously()
          .then((userCredential) => {
            // Store the user's UID locally
            setUid(userCredential.user?.uid);
          })
          .catch((error) => {
            console.log(error);
          });
    }, []); 

    const date = new Date();
    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });

    const handleBirthDayChange = (newValue) => {
        setBirthDay(newValue);
        setBirthDayChanged("true");
      };

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
        setPeriodChanged("true");
    };

    const handleInsuranceTypeChange = (event) => {
        setInsuranceType(event.target.value);
        setInsuranceTypeChanged("true");
    };
  
    const calculateAge = (birthDate) => {
      const currentDate = new Date();
      const birth = new Date(birthDate);
      const age = currentDate.getFullYear() - birth.getFullYear();
      const monthDifference = currentDate.getMonth() - birth.getMonth();  
      if (monthDifference < 0 || (monthDifference === 0 && currentDate.getDate() < birth.getDate())) {
        return age - 1;
      }  
      return age;
    };

    const getCountryName = (countryId) => {
        const countryMap = {
            1: 'Europe (UE+EEA)',
            2: 'Afghanistan',
            3: 'Albania',
            4: 'Algeria',
            5: 'Andorra',
            6: 'Angola',
            7: 'Antigua and Barbuda',
            8: 'Argentina',
            9: 'Armenia',
            10: 'Australia',
            11: 'Azerbaijan',
            12: 'Bahamas',
            13: 'Bahrain',
            14: 'Bangladesh',
            15: 'Barbados',
            16: 'Belarus',
            17: 'Belize',
            18: 'Benin',
            19: 'Bhutan',
            20: 'Bolivia',
            21: 'Bosnia and Herzegovina',
            22: 'Botswana',
            23: 'Brazil',
            24: 'Brunei',
            25: 'Burkina Faso',
            26: 'Burundi',
            27: 'Cabo Verde',
            28: 'Cambodia',
            29: 'Cameroon',
            30: 'Canada',
            31: 'Central African Republic (CAR)',
            32: 'Chad',
            33: 'Chile',
            34: 'China',
            35: 'Colombia',
            36: 'Comoros',
            37: 'Congo, Democratic Republic of the',
            38: 'Congo, Republic of the',
            39: 'Costa Rica',
            40: "Cote d'Ivoire",
            41: 'Cuba',
            42: 'Djibouti',
            43: 'Dominica',
            44: 'Dominican Republic',
            45: 'Ecuador',
            46: 'Egypt',
            47: 'El Salvador',
            48: 'Equatorial Guinea',
            49: 'Eritrea',
            50: 'Eswatini',
            51: 'Ethiopia',
            52: 'Fiji',
            53: 'Gabon',
            54: 'Gambia',
            55: 'Georgia',
            56: 'Ghana',
            57: 'Grenada',
            58: 'Guatemala',
            59: 'Guinea',
            60: 'Guinea-Bissau',
            61: 'Guyana',
            62: 'Haiti',
            63: 'Honduras',
            64: 'India',
            65: 'Indonesia',
            66: 'Iran',
            67: 'Iraq',
            68: 'Israel',
            69: 'Jamaica',
            70: 'Japan',
            71: 'Jordan',
            72: 'Kazakhstan',
            73: 'Kenya',
            74: 'Kiribati',
            75: 'Kosovo',
            76: 'Kuwait',
            77: 'Kyrgyzstan',
            78: 'Laos',
            79: 'Lebanon',
            80: 'Lesotho',
            81: 'Liberia',
            82: 'Libya',
            83: 'Madagascar',
            84: 'Malawi',
            85: 'Malaysia',
            86: 'Maldives',
            87: 'Mali',
            88: 'Marshall Islands',
            89: 'Mauritania',
            90: 'Mauritius',
            91: 'Mexico',
            92: 'Micronesia',
            93: 'Moldova',
            94: 'Monaco',
            95: 'Mongolia',
            96: 'Montenegro',
            97: 'Morocco',
            98: 'Mozambique',
            99: 'Myanmar',
            100: 'Namibia',
            101: 'Nauru',
            102: 'Nepal',
            103: 'New Zealand',
            104: 'Nicaragua',
            105: 'Niger',
            106: 'Nigeria',
            107: 'North Korea',
            108: 'North Macedonia',
            109: 'Oman',
            110: 'Pakistan',
            111: 'Palau',
            112: 'Palestine',
            113: 'Panama',
            114: 'Papua New Guinea',
            115: 'Paraguay',
            116: 'Peru',
            117: 'Philippines',
            118: 'Qatar',
            119: 'Romania',
            120: 'Russia',
            121: 'Rwanda',
            122: 'Saint Kitts and Nevis',
            123: 'Saint Lucia',
            124: 'Saint Vincent and the Grenadines',
            125: 'Samoa',
            126: 'San Marino',
            127: 'Sao Tome and Principe',
            128: 'Saudi Arabia',
            129: 'Senegal',
            130: 'Serbia',
            131: 'Seychelles',
            132: 'Sierra Leone',
            133: 'Singapore',
            134: 'Solomon Islands',
            135: 'Somalia',
            136: 'South Africa',
            137: 'South Korea',
            138: 'South Sudan',
            139: 'Sri Lanka',
            140: 'Sudan',
            141: 'Suriname',
            142: 'Switzerland',
            143: 'Syria',
            144: 'Taiwan',
            145: 'Tajikistan',
            146: 'Tanzania',
            147: 'Thailand',
            148: 'Timor-Leste',
            149: 'Togo',
            150: 'Tonga',
            151: 'Trinidad and Tobago',
            152: 'Tunisia',
            153: 'Turkey',
            154: 'Turkmenistan',
            155: 'Tuvalu',
            156: 'Uganda',
            157: 'Ukraine',
            158: 'United Arab Emirates (UAE)',
            159: 'United Kingdom (UK)',
            160: 'United States of America (USA)',
            161: 'Uruguay',
            162: 'Uzbekistan',
            163: 'Vanuatu',
            164: 'Vatican City (Holy See)',
            165: 'Venezuela',
            166: 'Vietnam',
            167: 'Yemen',
            168: 'Zambia',
            169: 'Zimbabwe',
            
        };
        return countryMap[countryId];
      };
  
    const calculatePrice = async (event) => {
        event.preventDefault();
        setOpen(true);
        setBirthDayChanged("false");
        setInsuranceTypeChanged("false");
        setPeriodChanged("false");
      const age = calculateAge(birthDay);
      let basePrice;
  
      switch (period) {
        case 90:
          basePrice = insuranceType === 5000 ? 500 : insuranceType === 20000 ? 1110 : 1995;
          break;
        case 180:
          basePrice = insuranceType === 5000 ? 835 : insuranceType === 20000 ? 1796 : 2964;
          break;
        case 365:
          basePrice = insuranceType === 5000 ? 1280 : insuranceType === 20000 ? 2839 : 4497;
          break;
        default:
          basePrice = 0;
      }
  
      if (age >= 0 && age <= 14 || age >= 55 && age <= 65) {
        basePrice *= 1.5;
      } else if (age >= 66 && age <= 75) {
        basePrice *= 2.0;
      } else if (age >= 76 && age <= 83) {
        basePrice *= 3.0;
      } else if (age > 84) {
        basePrice *= 4.0;
      }
  
      setPrice(basePrice);

      const docId = generateDocId();
      
          try {
            await db.collection("rcarequests2").doc(docId).set({
              birthDay:  dayjs(birthDay).format("DD.MM.YYYY"),
              product_type: "Medicala Facultativa",
              price: basePrice.toFixed(2),
              zone: getCountryName(country),
              insuranceType: insuranceType,
              datetime: formattedDate,
              period: period,
              uid: auth.currentUser.uid,
            });
      
            console.log("Document written with ID: ", docId);
            localStorage.setItem("docId", docId);
            localStorage.setItem("uid", uid);
          } catch (error) {
            console.error("Error adding document: ", error);
          }
    };


    const handleDetails = async () => {
        try {
          // Get the document ID from the previous submission
          const docId = localStorage.getItem("docId");
          localStorage.setItem("appLanguage", i18n.language);

          // Get the document data from Firestore
          const doc = await db.collection("rcarequests2").doc(docId).get();
      
          // Redirect to the details page, passing the encoded data as a parameter
          window.location.href = `./MedicalaFacultativa/${docId}`;
        } catch (error) {
          console.log(error);
        }
    };


    return (
        <div>
          <Helmet>
                <title>{t('medFacult')}</title>
                <meta name="description" content="Calculează prețul și cumpără online asigurare medicală facultativă. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta name="keywords" content="asigurare medicală, asigurare facultativă, calculator asigurare medicală, cumpără asigurare medicală online, asigurări de sănătate, asigurări online" />
                <meta name="author" content="Asig24.md" />
                <meta property="og:title" content="Asigurare medicală facultativă" />
                <meta property="og:description" content="Calculează prețul și cumpără online asigurare medicală facultativă. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta property="og:url" content="https://www.asig24.md/medical-optional" />
                <meta property="og:type" content="website"></meta>
                <link rel="canonical" href="https://www.asig24.md/medical-optional" />
            </Helmet>
            <Nav />
                <div className="parent">
                    <div className="forma">    

                    <div className="card" id="medicala-facultativa" style={{ marginBottom: '1rem' }}>
  <div className="icon icon-medicala-facultativa"></div>
    <div className="card-content">
      <h2>{t('medFacult')}</h2>
      <p>{t('medFacultDesc')}</p>
      </div>
  </div>

                        <form onSubmit={(event) => calculatePrice(event)}>

                            <div className="mbfield">
                                <FormControl fullWidth required>
                                    <InputLabel>{t('resedinta')}</InputLabel>
                                        <Select                                 
                                        label={t('resedinta')} value={country}  onChange={(e) => {
                                            const selectedCountry = parseInt(e.target.value);
                                            setCountry(selectedCountry);
                                        }}>
                                        {Object.entries({
                                          1: 'Europe (UE+EEA)',
                                          2: 'Afghanistan',
                                          3: 'Albania',
                                          4: 'Algeria',
                                          5: 'Andorra',
                                          6: 'Angola',
                                          7: 'Antigua and Barbuda',
                                          8: 'Argentina',
                                          9: 'Armenia',
                                          10: 'Australia',
                                          11: 'Azerbaijan',
                                          12: 'Bahamas',
                                          13: 'Bahrain',
                                          14: 'Bangladesh',
                                          15: 'Barbados',
                                          16: 'Belarus',
                                          17: 'Belize',
                                          18: 'Benin',
                                          19: 'Bhutan',
                                          20: 'Bolivia',
                                          21: 'Bosnia and Herzegovina',
                                          22: 'Botswana',
                                          23: 'Brazil',
                                          24: 'Brunei',
                                          25: 'Burkina Faso',
                                          26: 'Burundi',
                                          27: 'Cabo Verde',
                                          28: 'Cambodia',
                                          29: 'Cameroon',
                                          30: 'Canada',
                                          31: 'Central African Republic (CAR)',
                                          32: 'Chad',
                                          33: 'Chile',
                                          34: 'China',
                                          35: 'Colombia',
                                          36: 'Comoros',
                                          37: 'Congo, Democratic Republic of the',
                                          38: 'Congo, Republic of the',
                                          39: 'Costa Rica',
                                          40: "Cote d'Ivoire",
                                          41: 'Cuba',
                                          42: 'Djibouti',
                                          43: 'Dominica',
                                          44: 'Dominican Republic',
                                          45: 'Ecuador',
                                          46: 'Egypt',
                                          47: 'El Salvador',
                                          48: 'Equatorial Guinea',
                                          49: 'Eritrea',
                                          50: 'Eswatini',
                                          51: 'Ethiopia',
                                          52: 'Fiji',
                                          53: 'Gabon',
                                          54: 'Gambia',
                                          55: 'Georgia',
                                          56: 'Ghana',
                                          57: 'Grenada',
                                          58: 'Guatemala',
                                          59: 'Guinea',
                                          60: 'Guinea-Bissau',
                                          61: 'Guyana',
                                          62: 'Haiti',
                                          63: 'Honduras',
                                          64: 'India',
                                          65: 'Indonesia',
                                          66: 'Iran',
                                          67: 'Iraq',
                                          68: 'Israel',
                                          69: 'Jamaica',
                                          70: 'Japan',
                                          71: 'Jordan',
                                          72: 'Kazakhstan',
                                          73: 'Kenya',
                                          74: 'Kiribati',
                                          75: 'Kosovo',
                                          76: 'Kuwait',
                                          77: 'Kyrgyzstan',
                                          78: 'Laos',
                                          79: 'Lebanon',
                                          80: 'Lesotho',
                                          81: 'Liberia',
                                          82: 'Libya',
                                          83: 'Madagascar',
                                          84: 'Malawi',
                                          85: 'Malaysia',
                                          86: 'Maldives',
                                          87: 'Mali',
                                          88: 'Marshall Islands',
                                          89: 'Mauritania',
                                          90: 'Mauritius',
                                          91: 'Mexico',
                                          92: 'Micronesia',
                                          93: 'Moldova',
                                          94: 'Monaco',
                                          95: 'Mongolia',
                                          96: 'Montenegro',
                                          97: 'Morocco',
                                          98: 'Mozambique',
                                          99: 'Myanmar',
                                          100: 'Namibia',
                                          101: 'Nauru',
                                          102: 'Nepal',
                                          103: 'New Zealand',
                                          104: 'Nicaragua',
                                          105: 'Niger',
                                          106: 'Nigeria',
                                          107: 'North Korea',
                                          108: 'North Macedonia',
                                          109: 'Oman',
                                          110: 'Pakistan',
                                          111: 'Palau',
                                          112: 'Palestine',
                                          113: 'Panama',
                                          114: 'Papua New Guinea',
                                          115: 'Paraguay',
                                          116: 'Peru',
                                          117: 'Philippines',
                                          118: 'Qatar',
                                          119: 'Romania',
                                          120: 'Russia',
                                          121: 'Rwanda',
                                          122: 'Saint Kitts and Nevis',
                                          123: 'Saint Lucia',
                                          124: 'Saint Vincent and the Grenadines',
                                          125: 'Samoa',
                                          126: 'San Marino',
                                          127: 'Sao Tome and Principe',
                                          128: 'Saudi Arabia',
                                          129: 'Senegal',
                                          130: 'Serbia',
                                          131: 'Seychelles',
                                          132: 'Sierra Leone',
                                          133: 'Singapore',
                                          134: 'Solomon Islands',
                                          135: 'Somalia',
                                          136: 'South Africa',
                                          137: 'South Korea',
                                          138: 'South Sudan',
                                          139: 'Sri Lanka',
                                          140: 'Sudan',
                                          141: 'Suriname',
                                          142: 'Switzerland',
                                          143: 'Syria',
                                          144: 'Taiwan',
                                          145: 'Tajikistan',
                                          146: 'Tanzania',
                                          147: 'Thailand',
                                          148: 'Timor-Leste',
                                          149: 'Togo',
                                          150: 'Tonga',
                                          151: 'Trinidad and Tobago',
                                          152: 'Tunisia',
                                          153: 'Turkey',
                                          154: 'Turkmenistan',
                                          155: 'Tuvalu',
                                          156: 'Uganda',
                                          157: 'Ukraine',
                                          158: 'United Arab Emirates (UAE)',
                                          159: 'United Kingdom (UK)',
                                          160: 'United States of America (USA)',
                                          161: 'Uruguay',
                                          162: 'Uzbekistan',
                                          163: 'Vanuatu',
                                          164: 'Vatican City (Holy See)',
                                          165: 'Venezuela',
                                          166: 'Vietnam',
                                          167: 'Yemen',
                                          168: 'Zambia',
                                          169: 'Zimbabwe',
                                        }).map(([key, value]) => (
                                          <MenuItem key={key} value={key}>
                                            {value}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div className="mbfield">
                                <FormControl fullWidth required>
                                    <InputLabel>{t('insuredPeriod')}</InputLabel>
                                        <Select 
                                            label={t('insuredPeriod')}
                                            value={period} onChange={handlePeriodChange}>
                                                <MenuItem value={90}>{t('m3')}</MenuItem>
                                                <MenuItem value={180}>{t('m6')}</MenuItem>
                                                <MenuItem value={365}>{t('m12')}</MenuItem>
                                        </Select>
                                </FormControl>
                            </div>

                            <div className="mbfield">
                                <FormControl fullWidth required>
                                    <InputLabel>{t('InsSum')}</InputLabel>
                                        <Select 
                                            label={t('InsSum')}
                                            value={insuranceType} onChange={handleInsuranceTypeChange}>
                                                <MenuItem value={5000}>5000 MDL ({t('medserv')})</MenuItem>
                                                <MenuItem value={20000}>20000 MDL ({t('medserv2')})</MenuItem>
                                                <MenuItem value={30000}>30000 MDL ({t('medserv2')})</MenuItem>
                                        </Select>
                            </FormControl>
                            </div>
                           
                            <div className="mbfield">
                                <FormControl fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjs.locale('ro')}>                                        
                                        <DateField
                                            required
                                            label={t('birthday')}
                                            value={birthDay}
                                            onChange={(newValue) => handleBirthDayChange(newValue)}
                                            format="DD.MM.YYYY"
                                          />
                                    </LocalizationProvider>
                                </FormControl>
                            </div>

                            <div>    
                                {( birthDayChanged === "true" || 
                                    periodChanged === "true" ||
                                    insuranceTypeChanged === "true" ||
                                    price === 0
                                ) && (
                                <div align="center">
                                    <Button type="submit" color="error" variant="contained" sx={{ mt: '1rem' }}>
                                    {t('calcBtn')}
                                    </Button>
                                </div>
                                )}

                                {price > 0 && (
                                    <div className="boxresult">
                                        <Alert severity="success">
                                        <div>{t('insurancePrice')} <b>{price.toFixed(2)} LEI</b> </div>
                                        </Alert>
                                    </div>
                                )}

                                {(  birthDayChanged !== "true" && birthDayChanged !== '' && 
                                    periodChanged !== "true" && periodChanged !== '' &&
                                    insuranceTypeChanged !== "true" && insuranceTypeChanged !== '' &&
                                    price !== ""     
                                    ) && (
                                    <div align="center">                               
                                        <Button onClick={handleDetails}  variant="contained" sx={{ mt: '1rem' }}>
                                        {t('orderBtn')}
                                        </Button>                                     
                                    </div>    
                                )}
                            </div>

                        </form>    

                        <Dialog open={open} onClose={() => setOpen(false)}>
  <DialogTitle  sx={{ textAlign: 'center' }}><h1 >{price.toFixed(2)} {t('Lei')}</h1></DialogTitle>
  <DialogContent  sx={{ textAlign: 'center' }}>
    <DialogContentText>
      
    {t('orderPhone')}
      <div>
      <a href="tel:+37361111101">
  <Button type="button" variant="contained" color="error" size="large">
    061 111 101
  </Button>
</a>
</div>
<div style={{ display: 'flex', justifyContent: 'center' }}>
  <IconButton>
    <Link href="viber://chat?number=%2b37361111101">
      <img src={Vibericon} alt="Viber" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://wa.me/37361111101">
      <img src={Whatsappicon} alt="WhatsApp" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://t.me/+37361111101">
      <img src={Telegramicon} alt="Telegram" style={{ height: '45px' }} />
    </Link>
  </IconButton>
  <IconButton>
    <Link href="https://m.me/asig24">
      <img src={Messengericon} alt="Messenger" style={{ height: '45px' }} />
    </Link>
  </IconButton>
</div>
{t('or')}
      <div>
      <Button onClick={handleDetails}   variant="contained" size="large">
      {t('onlineOrder')}
                                        </Button>
                                        </div>
      </DialogContentText>
  </DialogContent>
  <DialogActions sx={{ pr: '8px' }}>
    <Button
      onClick={() => setOpen(false)}
      color="inherit"
      sx={{
        position: 'absolute',
        top: '1px',
        right: '1px',
      }}
    >
      X
    </Button>
  </DialogActions>
</Dialog>


                    </div>
                </div>

            <Footer /> 
       
        </div>
    );
}


