import React, { useState, useEffect } from "react";
import './Cards.css';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';



const Cards = () => {
  const { t } = useTranslation();
  return (
    
<div className="cards-container">

<Link to="../RCA" className="card-link">
  <div className="card" id="rca">
  <div className="icon icon-rca"></div>
    <div className="card-content">
      <h2>{t('cardsRCA')}</h2>
      <p>{t('cardsRCA2')}</p>    
      </div>
      <button>{t('cardsBtn')}</button>      
  </div>
</Link>

<Link to="../GreenCard" className="card-link">
  <div className="card" id="cartea-verde">
  <div className="icon icon-cartea-verde"></div>
    <div className="card-content">
      <h2>{t('cardsGreenCard')}</h2>
      <p>{t('cardsGreenCard2')}</p>   
      </div> 
      <button>{t('cardsBtn')}</button>      
  </div>
</Link>

<Link to="../CASCO" className="card-link">
  <div className="card" id="casco">
  <div className="icon icon-casco"></div>
    <div className="card-content">
      <h2>{t('cardsCASCO')}</h2>
      <p>{t('cardsCASCO2')}</p>
      </div>
      <button>{t('cardsBtn')}</button>  
    
  </div>
  </Link>

  <Link to="../MedicalaCalatorie" className="card-link">
  <div className="card" id="medicala-pentru-calatorii">
  <div className="icon icon-medicala-pentru-calatorii"></div>
    <div className="card-content">
      <h2>{t('cardsMedTr')}</h2>
      <p>{t('cardsMedTr2')}</p>
      </div>
      <button>{t('cardsBtn')}</button>  
      </div>
  </Link>
  
  <Link to="../MedicalaFacultativa" className="card-link">
  <div className="card" id="medicala-facultativa">
  <div className="icon icon-medicala-facultativa"></div>
    <div className="card-content">
      <h2>{t('cardsMedFac')}</h2>
      <p>{t('cardsMedFac2')}</p>
      </div>
      <button>{t('cardsBtn')}</button>  
    
  </div>
  </Link>

  <Link to="../Ipoteca" className="card-link">
  <div className="card" id="ipoteca">
  <div className="icon icon-ipoteca"></div>
    <div className="card-content">
      <h2>{t('cardsIpoteca')}</h2>
      <p>{t('cardsIpoteca2')}</p>
      </div>
      <button>{t('cardsBtn')}</button>  
  </div>
  </Link>

</div>

  );
};

export default Cards;
