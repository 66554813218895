import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import Divider from '@mui/material/Divider';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import { Button,  FormControl, InputLabel, Popover, MenuItem, Select, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton'
import { parseNumber } from "libphonenumber-js";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ro';
import 'dayjs/locale/ru';
import { useTranslation } from 'react-i18next';

 

firebase.initializeApp({
  apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
  authDomain: "masig-online.firebaseapp.com",
  projectId: "masig-online",
  storageBucket: "masig-online.appspot.com",
  messagingSenderId: "1064560601004",
  appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
  measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const InputField = styled(TextField)({
    marginBottom: '1rem',
});

const InputField2 = styled(TextField)({
    marginTop: '1rem',
});


export default function MedicalaFacultativaDdetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [period, setPeriod] = useState("");
    const [zone, setZone] = useState("");
    const [passNumber, setPassNumber] = useState("");
    const [price, setPrice] = useState("");
    const [deliveryPrice, setDeliveryPrice] = useState("0.00");
    const [amount, setAmount] = useState("");
    const [insuranceType, setInsuranceType] = useState("");
    const [name, setName] = useState("");
    const [birthDay, setBirthDay] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("online");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [adress, setAdress] = useState("");
    const [startDay, setStartDay] = useState("");
    const [endDay, setEndDay] = useState("");
    const [startDate, setStartDate] = React.useState(null);
    const [loading, setLoading] = useState(true);
    const [idnx, setIdnx] = useState('');
    const [idnxTouched, setIdnxTouched] = useState(false);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const openicon1 = Boolean(anchorEl1);
    const idicon1 = openicon1 ? 'simple-popover-1' : undefined;
    const openicon2 = Boolean(anchorEl2);
    const idicon2 = openicon2 ? 'simple-popover-2' : undefined;
    const { i18n, t } = useTranslation();


    useEffect(() => {
        if (price < 500 && deliveryMethod === "deliveryOnAdress") {
          setDeliveryPrice(59.00);
        } else {
          setDeliveryPrice(0.00);
        }
      }, [price, deliveryMethod]);

      useEffect(() => {
        const amount = Number(price) + Number(deliveryPrice);
        setAmount(Number(amount.toFixed(2)));
      }, [price, deliveryPrice]);


      const handleiconClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
      };
    
      const handleiconClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
      };
    
      const handleiconClose1 = () => {
        setAnchorEl1(null);
      };
    
      const handleiconClose2 = () => {
        setAnchorEl2(null);
      };

      const handleStartDateChange = (date) => {
        setStartDate(dayjs(date));      
        if (period === 90) {
          setEndDay(dayjs(date).add(3, "month").subtract(1, "day"));
        } else if (period === 180) {
          setEndDay(dayjs(date).add(6, "month").subtract(1, "day"));
        } else if (period === 360) {
          setEndDay(dayjs(date).add(12, "month").subtract(1, "day"));
        }
      };

      const handleIdnxChange = (e) => {
        setIdnx(e.target.value);
        setIdnxTouched(true);
      };
    
  
    useEffect(() => {
        const fetchData = async () => {
        try {
            const doc = await db.collection("rcarequests2").doc(id).get();
            setIdnx(doc.data().idnx);
            setData(doc.data());
            setPeriod(doc.data().period);
            setZone(doc.data().zone);
            setPrice(doc.data().price);
            setInsuranceType(doc.data().insuranceType);
            setDeliveryPrice(doc.data().deliveryPrice);
            setAmount(doc.data().amount);
            setName(doc.data().name);
            setBirthDay(doc.data().birthDay);
            setPhoneNumber(doc.data().phoneNumber);
            setDeliveryMethod(doc.data().deliveryMethod);
            setEmail(doc.data().email);
            setAdress(doc.data().adress);
            setStartDay(doc.data().startDay);
            setEndDay(doc.data().endDay);
            setLoading(false);
            } catch (error) {
            console.log(error);
            
            navigate('/'); 
        }
      };
      fetchData();
    }, [id]);


    useEffect(() => {
      const lang = localStorage.getItem("appLanguage");
      if (lang) {
        i18n.changeLanguage(lang); // Устанавливаем язык
      }
    }, [i18n]);



    const handleConfirmOrder = async (e) => {
      e.preventDefault();
      try {
        await db.collection("rcarequests2").doc(id).update({
            startDay: dayjs(startDate).format("DD.MM.YYYY"),
            endDay: dayjs(endDay).format("DD.MM.YYYY"),
            name,
            phoneNumber,
            passNumber,
            idnx: idnx || '',
            email: email || '',
            adress: adress || '',
            deliveryPrice: deliveryPrice,
            amount,
        });
        navigate(`/MedicalaFacultativa/${id}`);
        handlePayment();
      } catch (error) {
        console.log(error);
      }

      try {
        const formatDate = new Date(endDay);
        const formattedEndDay = formatDate.toLocaleDateString('ro-RO');  // 'en-GB' format is DD.MM.YYYY
      
        const response = await fetch('https://asig24.md/send-facultative', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id, name, phoneNumber, amount, price, deliveryPrice, deliveryMethod, idnx, passNumber, zone, insuranceType, birthDay, startDay, endDay: formattedEndDay, period, email, adress }),
        });
      
        if (response.ok) {
          // Handle successful submission
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle fetch error
      }
    };
  
    const handlePayment = () => {
      navigate(`/MedicalaFacultativa/order/${id}`);
    };

    const nameRegex = /^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u1E00-\u1EFF\s]*$/;

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsEmailValid(inputEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail));
    }; 


  return (    
    <div>
      <Helmet>
                <title>{t('medFacult')}</title>
                <meta name="description" content="Calculează prețul și cumpără online asigurare medicală facultativă. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta name="keywords" content="asigurare medicală, asigurare facultativă, calculator asigurare medicală, cumpără asigurare medicală online, asigurări de sănătate, asigurări online" />
                <meta name="author" content="Asig24.md" />
                <meta property="og:title" content="Asigurare medicală facultativă" />
                <meta property="og:description" content="Calculează prețul și cumpără online asigurare medicală facultativă. Rapid și sigur cu Asig24.md obții polița de asigurare medicală în câteva minute." />
                <meta property="og:url" content="https://www.asig24.md/medical-optional" />
                <meta property="og:type" content="website"></meta>
                <link rel="canonical" href="https://www.asig24.md/medical-optional" />
            </Helmet>        
        <Nav />

            <> {loading ? ( <div align="center" className="loading"><CircularProgress /></div> ) : ( <>

            <div className="parent">
                <div className="forma">

                
                    <div className="orderdetails">
                        <div className="orderdetailsrow">{t('resedinta')}: <b>{zone}</b></div>
                        <div className="orderdetailsrow">{t('insuredPeriod')} <b>{period === 90 && '(3 Luni)'}{period === 180 && '(6 Luni)'}{period === 360 && '(12 Luni)'}</b></div>
                        <div className="orderdetailsrow">{t('InsSum')}: <b>{insuranceType} €</b></div>

                        <Divider  />
                        <br></br>
                        <div className="orderdetailsrow">{t('insurancePrice')} <b>{price} {t('Lei')}</b></div>
                        {deliveryPrice > 0 && (<div className="orderdetailsrow">{t('deliveryPrice')} <b>{deliveryPrice} {t('Lei')}</b></div>)}
                        <Divider textAlign="right"><div className="orderdetailsrow"><b>{t('total')} {amount} {t('LEI')}</b></div></Divider> 
                    </div>
        


                    <form onSubmit={handleConfirmOrder}>     


                    <div className="mbfield">
                            <FormControl fullWidth required>
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"ro"} >
                                    <DatePicker    
                                        label={t('periodBegin')}
                                        format="DD.MM.YYYY"                                    
                                        value={startDate}
                                        disablePast={true}   
                                        onChange={(newValue) => { handleStartDateChange(newValue); }}
                                        slotProps={{ 
                                            textField: { variant: 'outlined', required: true },
                                            toolbar: {
                                              toolbarTitle: t('periodBegin'),
                                                toolbarPlaceholder: "__",
                                                toolbarFormat: "DD.MM.YYYY",
                                                hidden: false,
                                            }
                                        }}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            </div>
                   
                        

                        <InputField
                            required
                            label={t('labelName')}
                            value={name || ''}
                            inputProps={{ maxLength: 50 }}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={t('labelName')}
                            variant="outlined"
                            error={name !== "" && !nameRegex.test(name)}
                            helperText={name !== "" && !nameRegex.test(name) && `${t('nameError')}`}
                            fullWidth
                        />

                        <InputField
                            required
                            label={t('labelIdno')}
                            value={idnx || ''}
                            inputProps={{ minLength: 5, maxLength: 20}}
                            InputProps={{
                                endAdornment: (            
                                    <InputAdornment disableTypography position="end" className='helpicon'>
                                        <IconButton onClick={handleiconClick1}  >            
                                            <HelpOutlineIcon />            
                                        </IconButton>
                                    </InputAdornment>  
                                ),                            
                            }}
                            onChange={handleIdnxChange}
                            placeholder={t('labelIdno')}
                            variant="outlined"
                            fullWidth                            
                        />
                        <Popover
                            id={idicon1}
                            open={openicon1}
                            anchorEl={anchorEl1}
                            onClose={handleiconClose1}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            >
                            <div className="popovermsg">
                            {t('popoverMesage4')}
                            </div>
                        </Popover>

                        <InputField
                            required
                            label={t('docNumber')}
                            value={passNumber}
                            inputProps={{ minLength: 4, maxLength: 20 }}
                            onChange={(e) => setPassNumber(e.target.value)}
                            placeholder={t('docNumber')}
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                endAdornment: (            
                                  <InputAdornment disableTypography position="end" className='helpicon'>
                                    <IconButton onClick={handleiconClick2}  >            
                                      <HelpOutlineIcon />            
                                    </IconButton>
                                  </InputAdornment>  
                                ),
                                
                            }}
                        />
                        <Popover
                            id={idicon2}
                            open={openicon2}
                            anchorEl={anchorEl2}
                            onClose={handleiconClose2}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            >
                            <div className="popovermsg">
                            {t('popoverMesage5')}
                            </div>
                        </Popover>

                        <InputField
                            required
                            label={t('labelPhone')}
                            value={phoneNumber || ''}
                            inputProps={{ 
                                minLength: 8, 
                                maxLength: 15, 
                                pattern: '^\\+?[0-9]{7,14}$',
                                type: 'tel',
                                inputMode: 'tel',                            
                            }}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="+373 69******"
                            variant="outlined" 
                            fullWidth
                        />

<FormControl fullWidth>
                            <InputLabel>{t('labelDelivery')} </InputLabel>
                                <Select 
                                    required
                                    label={t('labelDelivery')} 
                                    value={deliveryMethod || ''} onChange={(e) => setDeliveryMethod(e.target.value)}>
                                        <MenuItem value={"online"}>{t('onlineDelivery')}</MenuItem>
                                        <MenuItem value={"fromOffice"}>{t('fromOfficeDelivery')}</MenuItem>
                                        {amount > 500 && (<MenuItem value={"deliveryOnAdress"}>{t('onAdressDelivery')}</MenuItem>)}                     
                                </Select>
                        </FormControl>  

                        {deliveryMethod === "online" && (
                        <InputField2
                            label={t('labelEmail')}
                            value={email  || ''}
                            inputProps={{ maxLength: 50 }}
                            onChange={handleEmailChange}
                            placeholder={t('labelEmail')}
                            variant="outlined"
                            error={!isEmailValid}
                            helperText={!isEmailValid ? `${t('emailError')}` : ""}
                            fullWidth
                        />
                        )}

{deliveryMethod === "deliveryOnAdress" && (
                        <InputField2
                            label={t('deliveryAdress')}
                            value={adress  || ''}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => setAdress(e.target.value)}
                            placeholder={t('deliveryAdress')}
                            variant="outlined"
                            fullWidth
                            required
                        />
                        )}

                            <FormControl>
                                <FormControlLabel          
                                    control={
                                    <Checkbox required />
                                    }            
                                    label={
                                        <div className="checkbox">
                                           {t('labelCheckBox')}
                                        </div>
                                    }/>          
                            </FormControl>  
                        <br />
                        <br />
                        <div align="center" className="placeorderbutton">
                            <Button variant="contained" type="submit">{t('confirmOrderBtn')}</Button>
                        </div>
                    </form>
                        
                </div>
            </div>
        <Footer /> 
        </> )} </>
    </div>   
  
  );
}