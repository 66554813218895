import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import logoImg from "./logo192.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import ViberIcon from '../logos/viber.png';
import Visa from '../logos/visa.png';
import Mastercard from '../logos/mastercard.png';
import Mpay from '../logos/mpay.png';
import Gpay from '../logos/google-pay.svg';
import Apay from '../logos/apple-pay.svg';
import { useTranslation } from 'react-i18next';


export default function Footer(){

    const { t } = useTranslation();


  return(
    <div>
        <div className="footer">
          <div className="footerbox">
          <Link to="../Home"><img src={logoImg} alt="Asig24 Logo" height="60" /></Link> 
          <div className="social-icons">
            <a href="https://m.me/asig24" target="_blank" rel="noopener noreferrer">
              <FacebookIcon />
            </a>
            <a href="https://ig.me/m/asig24.md" target="_blank" rel="noopener noreferrer">
              <InstagramIcon />
            </a>
            <a href="viber://chat?number=%2b37361111101" target="_blank" rel="noopener noreferrer">
            <img src={ViberIcon} alt="viber" height="22" />
            </a>
            {/* Replace "#" with your Viber and WhatsApp links */}
            <a href="https://t.me/+37361111101" target="_blank" rel="noopener noreferrer">
              <TelegramIcon />
            </a>
            <a href="https://wa.me/37361111101" target="_blank" rel="noopener noreferrer">
              <WhatsAppIcon />
            </a>
            
          </div>         
          <p><b>PRIMGROUP-ASIG S.R.L.</b></p>
          <p>Email: support@asig24.md</p>
          <p>Tel: +373 61 111 101</p>
          <p>mun. Chișinău, str. Miron Costin 14</p>
          <p>IDNO: 1012600036968</p>
          
          </div>
          <div className="footerbox">
              <div className="footerboxtitle"><b>{t('footerTitleIns')}</b></div>
              <Link to="../RCA">{t('cardsRCA')}</Link>           
              <Link to="../GreenCard">{t('cardsGreenCard')}</Link>      
              <Link to="../CASCO">{t('cardsCASCO')}</Link> 
              <Link to="../MedicalaCalatorie">{t('cardsMedTr')}</Link>
              <Link to="../MedicalaFacultativa">{t('cardsMedFac')}</Link>
              <Link to="../Ipoteca">{t('cardsIpoteca')}</Link>                          
           </div>
           <div className="footerbox">
              <div className="footerboxtitle"><b>{t('footerTitleIns2')}</b></div>
              <Link to="../Termsofservices">{t('Termsofservices')}</Link>           
              <Link to="../Privacy">{t('Privacy')}</Link>      
              <Link to="../Termsofdelivery">{t('Termsofdelivery')}</Link> 
              <Link to="../Affidavit">{t('Affidavit')}</Link> 
              <Link to="../Contact">{t('contacts')}</Link>                         
           </div>           
        </div>
        <div className="copyright" align="center">
        <div className="payment-icons">
          <img src={Visa} alt="Asig24 Logo" height="16" />
          <img src={Mastercard} alt="Asig24 Logo" height="16" />
          <img src={Mpay} alt="Asig24 Logo" height="16" />
          <img src={Gpay} alt="Asig24 Logo" height="16" />
          <img src={Apay} alt="Asig24 Logo" height="16" />
          </div>
          © 2025. {t('copyright')}</div>
        </div>
        
  );

}