import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useParams, useNavigate } from "react-router-dom";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import Divider from '@mui/material/Divider';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import CircularProgress from '@mui/material/CircularProgress';
import { Button,  FormControl, InputLabel, ListItemIcon, MenuItem, Select, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import "./style.css";
import { useTranslation } from 'react-i18next';

 

firebase.initializeApp({
  apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
  authDomain: "masig-online.firebaseapp.com",
  projectId: "masig-online",
  storageBucket: "masig-online.appspot.com",
  messagingSenderId: "1064560601004",
  appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
  measurementId: "G-GV5N5FV941"
});

const db = firebase.firestore();
const auth = firebase.auth();

const InputField = styled(TextField)({
    marginBottom: '1rem',
});

const InputField2 = styled(TextField)({
    marginTop: '1rem',
});

export default function RCADdetails() {
    const { id } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [idnx, setIdnx] = useState("");    
    const [vehicleRegistrationCertificateNumber, setVehicleRegistrationCertificateNumber] = useState("");
    const [unlimitedPersons, setUnlimitedPersons] = useState("");
    const [personIsJuridical, setPersonIsJuridical] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [persons, setPersons] = useState("");
    const [bonusMalus, setBonusMalus] = useState("");
    const [price, setPrice] = useState("");
    const [deliveryPrice, setDeliveryPrice] = useState(0.00);
    const [amount, setAmount] = useState("");
    const [operatingModes, setOperatingModes] = useState("");
    const [name, setName] = useState("");
    const [rcaName, setRcaName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [deliveryMethod, setDeliveryMethod] = useState("online");
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [adress, setAdress] = useState("");
    const [ownership, setOwnership] = useState("");
    const [insuranceCompany, setInsuranceCompany] = useState("");
    const [startDay, setStartDay] = useState("");
    const startDate = dayjs(startDay, "DD.MM.YYYY");
    const endDay = startDate.add(1, 'year').subtract(1, 'day').format('DD.MM.YYYY');
    const [loading, setLoading] = useState(true);
    const { i18n, t } = useTranslation();



    useEffect(() => {
      const lang = localStorage.getItem("appLanguage");
      if (lang) {
        i18n.changeLanguage(lang); // Устанавливаем язык
      }
    }, [i18n]);


    
    useEffect(() => {
        if (price < 500 && deliveryMethod === "deliveryOnAdress") {
          setDeliveryPrice(59);
        } else {
          setDeliveryPrice(0.00);
        }
      }, [price, deliveryMethod]);

      useEffect(() => {
        const amount = Number(price) + Number(deliveryPrice);
        setAmount(`${Number(amount.toFixed(2)).toFixed(2)}`);
    }, [price, deliveryPrice]);
    
  
    useEffect(() => {
        const fetchData = async () => {
        try {
            const doc = await db.collection("rcarequests2").doc(id).get();
            setIdnx(doc.data().idnx);
            setData(doc.data());
            setVehicleRegistrationCertificateNumber(doc.data().vehicleRegistrationCertificateNumber);
            setUnlimitedPersons(doc.data().unlimitedPersons); 
            setPersonIsJuridical(doc.data().personIsJuridical);
            setCompanyName(doc.data().companyName);
            setRcaName(doc.data().rcaName);
            setPersons(doc.data().persons);
            setBonusMalus(doc.data().bonusMalus);
            setPrice(doc.data().price);
            setDeliveryPrice(doc.data().deliveryPrice);
            setAmount(doc.data().amount);
            setOperatingModes(doc.data().operatingModes);
            setName(doc.data().name);
            setPhoneNumber(doc.data().phoneNumber);
            setDeliveryMethod(doc.data().deliveryMethod);
            setEmail(doc.data().email);
            setAdress(doc.data().adress);
            setOwnership(doc.data().ownership);
            setInsuranceCompany(doc.data().insuranceCompany);
            setStartDay(doc.data().startDay);
            setLoading(false);
            } catch (error) {
            console.log(error);
            
            navigate('/'); 
        }
      };
      fetchData();
    }, [id]);


    const handleConfirmOrder = async (e) => {
      e.preventDefault();
      try {
        await db.collection("rcarequests2").doc(id).update({
            name,
            phoneNumber,
            deliveryMethod,
            email: email || '',
            adress: adress || '',
            deliveryPrice: deliveryPrice,
            amount,
        });
        navigate(`/RCA/${id}`);
        handlePayment();
      } catch (error) { 
        console.log(error);
      }

      try {
        const response = await fetch('https://asig24.md/send-rca', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id, idnx, vehicleRegistrationCertificateNumber, rcaName, startDay, deliveryPrice, price, amount, bonusMalus, insuranceCompany, ownership, operatingModes, personIsJuridical, deliveryMethod, name, companyName, phoneNumber, email, adress }),
        });
    
        if (response.ok) {
          // Handle successful submission
        } else {
          // Handle error
        }
      } catch (error) {
        // Handle fetch error
      }
    };
  
    const handlePayment = () => {
      navigate(`/RCA/order/${id}`);
    };

    const nameRegex = /^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u1E00-\u1EFF\s]*$/;

    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsEmailValid(inputEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail));
    };
      

  return (    
    <div>
        <Helmet>
            <title>Asigurare RCA online</title>
            <meta name="description" content="Calculează și cumpără asigurare RCA online. Rapid și sigur cu Asig24.md obții polița ta de asigurare RCA în câteva minute." />
            <meta name="keywords" content="RCA, RCA online, asigurări, calculator RCA, asigurare auto, asigurare RCA Moldova, asigurări online" />
            <meta name="author" content="Asig24.md" />
            <meta property="og:title" content="Asigurare RCA online" />
            <meta property="og:description" content="Calculează și cumpără asigurare RCA online. Rapid și sigur cu Asig24.md obții polița ta de asigurare RCA în câteva minute." />
            <meta property="og:url" content="https://www.asig24.md/RCA" />
            <meta property="og:type" content="website"></meta>
            <link rel="canonical" href="https://www.asig24.md/RCA" />
        </Helmet>        
        <Nav />

            <> {loading ? ( <div align="center" className="loading"><CircularProgress /></div> ) : ( <>

            <div className="parent">
                <div className="forma">
                    

                    <div className="orderdetails">
                    <div className="orderdetailsrow">{t('insured')} <b>{idnx} {rcaName}</b></div>
                    <div className="orderdetailsrow">{t('bonusMalus')} <b>{bonusMalus}</b></div>
                    <div className="orderdetailsrow">{t('labelVehReg')} <b>{vehicleRegistrationCertificateNumber}</b></div>
                    <div className="orderdetailsrow">{t('insuredPeriod')} <b>{startDay} - {endDay}</b></div>
                    <div className="orderdetailsrow">{t('selectedInsuranceCompany')} <b>{insuranceCompany}</b></div>
                        
                        <Divider  />
                        <br></br>
                        <div className="orderdetailsrow">{t('insurancePrice')} <b>{price} {t('Lei')}</b></div>
                        {deliveryPrice > 0 && (<div className="orderdetailsrow">{t('deliveryPrice')} <b>{deliveryPrice} {t('Lei')}</b></div>)}
                        <Divider textAlign="right"><div className="orderdetailsrow"><b>{t('total')} {amount} {t('LEI')}</b></div></Divider> 
                    </div>
        


                    <form onSubmit={handleConfirmOrder}>    


                        <InputField
                            required
                            label={t('contactPerson')}
                            value={name || ''}
                            inputProps={{ maxLength: 50 }}
                            onChange={(e) => setName(e.target.value)}
                            placeholder={t('contactPerson')}
                            variant="outlined"
                            error={name !== "" && !nameRegex.test(name)}
                            helperText={name !== "" && !nameRegex.test(name) ? `${t('nameError')}` : ""}
                            fullWidth
                        />

                        <InputField
                            required
                            label={t('labelPhone')}
                            value={phoneNumber || ''}
                            inputProps={{ 
                                minLength: 8, 
                                maxLength: 15, 
                                pattern: '^\\+?[0-9]{7,14}$',
                                type: 'tel',
                                inputMode: 'tel',                            
                            }}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="+373 69******"
                            variant="outlined" 
                            fullWidth
                        />

                        <FormControl fullWidth>
                            <InputLabel>{t('labelDelivery')}</InputLabel>
                                <Select 
                                    required
                                    label={t('labelDelivery')}
                                    value={deliveryMethod || ''} onChange={(e) => setDeliveryMethod(e.target.value)}>
                                        <MenuItem value={"online"}>{t('onlineDelivery')}</MenuItem>
                                        <MenuItem value={"fromOffice"}>{t('fromOfficeDelivery')}</MenuItem>
                                        <MenuItem value={"deliveryOnAdress"}>{t('onAdressDelivery')}</MenuItem>                        
                                </Select>
                        </FormControl>  

                        {deliveryMethod === "online" && (
                        <InputField2
                            label={t('labelEmail')}
                            value={email  || ''}
                            inputProps={{ maxLength: 50 }}
                            onChange={handleEmailChange}
                            placeholder={t('labelEmail')}
                            variant="outlined"
                            error={!isEmailValid}
                            helperText={!isEmailValid ? `${t('emailError')}` : ""}
                            fullWidth
                        />
                        )}

                        {deliveryMethod === "deliveryOnAdress" && (
                        <InputField2
                            label={t('deliveryAdress')}
                            value={adress  || ''}
                            inputProps={{ maxLength: 150 }}
                            onChange={(e) => setAdress(e.target.value)}
                            placeholder={t('deliveryAdress')}
                            variant="outlined"
                            fullWidth
                            required
                        />
                        )}

                        
                        <br />
                        <br />
                        <div align="center" className="placeorderbutton">
                            <Button variant="contained" type="submit">{t('confirmOrderBtn')}</Button>
                        </div>
                    </form>
                        
                </div>
            </div>
        <Footer /> 
        </> )} </>
    </div>   
  
  );
}