import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Nav from "../../components/navigation/Nav.js"
import Footer from "../../components/footer/Footer.js"
import { Button,  FormControl, InputLabel, Popover, MenuItem, Select, FormControlLabel, Checkbox, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from "@mui/material/Alert";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { useTranslation } from 'react-i18next';


firebase.initializeApp({
    apiKey: "AIzaSyCIEjcrcqB91GSTwdJp63gc7v-P03FlITI",
    authDomain: "masig-online.firebaseapp.com",
    projectId: "masig-online",
    storageBucket: "masig-online.appspot.com",
    messagingSenderId: "1064560601004",
    appId: "1:1064560601004:web:4eb4fa0c1e947405c13c09",
    measurementId: "G-GV5N5FV941"
  });
  
  const db = firebase.firestore();
  const auth = firebase.auth();
  
  const generateDocId = () => {
    // Generate 5 random digits
    const randomDigits = Math.floor(1000000 + Math.random() * 9999999);
  
    // Generate 3 random letters
    const letters = "ABCDEFGHJKLMNPQRSTUVWXYZ";
    const randomLetters = letters.charAt(Math.floor(Math.random() * letters.length)) +
                            letters.charAt(Math.floor(Math.random() * letters.length)) + 
                            letters.charAt(Math.floor(Math.random() * letters.length));
  
    // Combine digits and letters to form docId
    const docId = "CA" + randomDigits + randomLetters;
  
    return docId;
  };


const InputField = styled(TextField)({
    marginBottom: '1rem',
});

export default function Casco() {
    const [marca, setMarca] = useState("");
    const [model, setModel] = useState("");
    const [value, setValue] = useState("");
    const [year, setYear] = useState("");    
    const [email, setEmail] = useState("");
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const nameRegex = /^[a-zA-Z\u00C0-\u024F\u0400-\u04FF\u1E00-\u1EFF\s]*$/;
    const [productType, setProductType] = useState(""); 
    const [submitted, setSubmitted] = useState(false);
    const [uid, setUid] = useState("");
    const { i18n, t } = useTranslation();



    const handleEmailChange = (event) => {
        const inputEmail = event.target.value;
        setEmail(inputEmail);
        setIsEmailValid(inputEmail === "" || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail));
    }; 

  const date = new Date();
    const formattedDate = date.toLocaleString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    });
  

  useEffect(() => {
    auth.signInAnonymously()
      .then((userCredential) => {
        // Store the user's UID locally
        setUid(userCredential.user?.uid);
      })
      .catch((error) => {
        console.log(error);
      });
}, []); 

const handleSubmit = async (event) => {
  event.preventDefault();
  const docId = generateDocId();
        
  await db.collection("rcarequests2").doc(docId).set({
    name: name,
    marca,
    model,
    value,
    year,
    email,
    phoneNumber: phoneNumber,
    product_type: 'CASCO',
    datetime: formattedDate,
    uid: auth.currentUser.uid,
  })
  .then((docRef) => {
    console.log("Document written with ID: ", docId);
    localStorage.setItem("docId", docId);
    localStorage.setItem("uid", uid);
  })
  .catch((error) => {
    console.error("Error adding document: ", error);
  });

  try {
    const response = await fetch('https://asig24.md/send-casco', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ docId, name, phoneNumber, email, marca, model, value, year }),
    });
  
    if (response.ok) {
      // Handle successful submission
    } else {
      // Handle error
    }
  } catch (error) {
    // Handle fetch error
  }
  setSubmitted(true);
};

  return (
    <div>
        <Helmet>
            <title>{t('cardsCASCO')}</title>
            <meta name="description" content="Asigurare CASCO" />
            <meta name="keywords" content="CASCO, RCA, Cartea Verde, asigurări, asigurare auto, CASCO Moldova, Cartea Verde Moldova, asigurări online" />
            <meta name="author" content="Asig24.md" />
            <meta property="og:title" content="CASCO" />
            <meta property="og:description" content="Asigurare CASCO" />
            <meta property="og:url" content="https://www.asig24.md/CASCO" />
            <meta property="og:type" content="website"></meta>
            <link rel="canonical" href="https://www.asig24.md/CASCO" />
        </Helmet>
        <Nav />
            <div className="parent">
                <div className="forma">

                <div className="card" id="casco" style={{ marginBottom: '1rem' }}>
  <div className="icon icon-casco"></div>
    <div className="card-content">
      <h2>{t('cardsCASCO')}</h2>
      <p>{t('cascoDesc1')} <b>{t('cascoDesc2')}</b></p>
        
      </div>
  </div>

                <form onSubmit={handleSubmit}>
                    <InputField
                        required
                        label={t('Vmark')}
                        value={marca}
                        onChange={(e) => setMarca(e.target.value)}
                        inputProps={{ minLength: 2, maxLength: 20}}
                        placeholder={t('Vmark')}
                        variant="outlined"
                        fullWidth                        
                    />

                    <InputField
                        required
                        label={t('Vmodel')}
                        value={model}
                        onChange={(e) => setModel(e.target.value)}
                        inputProps={{ minLength: 1, maxLength: 20}}
                        placeholder={t('Vmodel')}
                        variant="outlined"
                        fullWidth                        
                    />
       
                    <InputField
                        required
                        label={t('Mvalue')}
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        inputProps={{ minLength: 4, maxLength: 6, pattern: '[0-9]*' }}
                        placeholder={t('Mvalue')}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            endAdornment: (            
                              <InputAdornment disableTypography position="start" className='helpicon'>
                                  EUR
                              </InputAdornment>  
                            ),
                            
                        }}
                    />

                    <div className="mbfield">
                    <FormControl fullWidth>
                        <InputLabel>{t('Myear')}</InputLabel>
                            <Select 
                                required
                                label={t('Myear')}          
                                value={year} onChange={(e) => setYear(e.target.value)}>
                                    <MenuItem value={"2016"}>2016</MenuItem>
                                    <MenuItem value={"2017"}>2017</MenuItem>
                                    <MenuItem value={"2018"}>2018</MenuItem>
                                    <MenuItem value={"2019"}>2019</MenuItem>
                                    <MenuItem value={"2020"}>2020</MenuItem>
                                    <MenuItem value={"2021"}>2021</MenuItem>
                                    <MenuItem value={"2022"}>2022</MenuItem>
                                    <MenuItem value={"2023"}>2023</MenuItem>
                                    <MenuItem value={"2024"}>2024</MenuItem>
                                    <MenuItem value={"2025"}>2025</MenuItem>
                            </Select>
                    </FormControl>
                    </div>

                    <InputField
                        required
                        label={t('contactPerson')}
                        value={name || ''}
                        inputProps={{ maxLength: 50 }}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t('contactPerson')}
                        variant="outlined"
                        error={name !== "" && !nameRegex.test(name)}
                        helperText={name !== "" && !nameRegex.test(name) && `${t('nameError')}`}
                        fullWidth
                    />

                    <InputField
                        required
                        label={t('labelPhone')}
                        value={phoneNumber || ''}
                        inputProps={{ 
                            minLength: 8, 
                            maxLength: 15, 
                            pattern: '^\\+?[0-9]{7,14}$',
                            type: 'tel',
                            inputMode: 'tel',                            
                        }}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="+373 69******"
                        variant="outlined" 
                        fullWidth
                    />

                    <InputField
                        label={t('labelEmail')}
                        value={email  || ''}
                        inputProps={{ maxLength: 50 }}
                        onChange={handleEmailChange}
                        placeholder={t('labelEmail')}
                        variant="outlined"
                        error={!isEmailValid}
                        helperText={!isEmailValid ? `${t('emailError')}` : ""}
                        fullWidth
                    />

                    {submitted !== true && (
                        <div align="center" className="placeorderbutton">
                            <Button variant="contained" type="submit">{t('getOffer')}</Button>
                        </div>
                    )}

                    {submitted === true && (
                        <div className="boxresult">
                            <Alert severity="success">
                                <div>
                                    <h2>{t('getOfferT1')}</h2>
                                    <p>{t('getOfferT2')}</p>
                                </div>
                            </Alert>
                        </div>
                    )}
                </form>



                </div>
            </div>
       
        <Footer />
    </div>
  );
}