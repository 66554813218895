import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import logoImg from "./logo192.png";
import ViberIcon from '../logos/viber.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useTranslation } from 'react-i18next';

export default function Nav() {
  useEffect(() => {
    const hamburger = document.querySelector(".hamburger");
    const navMenu = document.querySelector(".nav-menu");
    const navLink = document.querySelectorAll(".nav-link");

    hamburger.addEventListener("click", mobileMenu);
    navLink.forEach(n => n.addEventListener("click", closeMenu));

    function mobileMenu() {
      hamburger.classList.toggle("active");
      navMenu.classList.toggle("active");
    }

    function closeMenu() {
      hamburger.classList.remove("active");
      navMenu.classList.remove("active");
    }

    return () => {
      hamburger.removeEventListener("click", mobileMenu);
      navLink.forEach(n => n.removeEventListener("click", closeMenu));
    };
  }, []);

  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <header className="header">
      <nav className="navbar">
        <Link to="/Home" className="nav-logo"><img src={logoImg} alt="Asig24 Logo" height="50" /></Link>
        <ul className="nav-menu">
          <li className="nav-item">
            <Link to="/Home" className="nav-link">{t('home')}</Link>
          </li>
          <li className="nav-item">
            <Link to="/Contact" className="nav-link">{t('contacts')}</Link>
          </li>
          <li className="nav-item">
            <Link onClick={() => changeLanguage('ro')}  className="nav-link">RO</Link>
          </li>
          <li className="nav-item">
            <Link onClick={() => changeLanguage('ru')}  className="nav-link">RU</Link>
          </li>
          <li className="nav-item">
            <div className="nav-social-icons">
              <a href="https://m.me/asig24" target="_blank" rel="noopener noreferrer">
                <FacebookIcon />
              </a>
              <a href="https://ig.me/m/asig24.md" target="_blank" rel="noopener noreferrer">
                <InstagramIcon />
              </a>
              <a href="viber://chat?number=%2b37361111101" target="_blank" rel="noopener noreferrer">
                <img src={ViberIcon} alt="viber" height="22" />
              </a>
              <a href="https://t.me/+37361111101" target="_blank" rel="noopener noreferrer">
                <TelegramIcon />
              </a>
              <a href="https://wa.me/37361111101" target="_blank" rel="noopener noreferrer">
                <WhatsAppIcon />
              </a>
            </div>
          </li>
        </ul>
        <div className="hamburger">
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </div>
      </nav>
    </header>
  );
}
