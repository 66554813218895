import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
// Import your translation files
import roTranslations from './components/locales/ro.json';
import ruTranslations from './components/locales/ru.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      ro: {
        translation: roTranslations,
      },
      ru: {
        translation: ruTranslations,
      },
    },
    lng: 'ro', // Default language (Romanian)
    fallbackLng: 'ro', // Fallback language
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });
  

createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);